<template>
  <ui-component-modal
    :modalTitle="$t('Components.Reservation.ModalSendMail.Main_Title')"
    modalSize="large"
    :buttonLabelConfirm="'Send'"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :disableSaveButton="isSaving || !validEmailSettings"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="sendMail"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <transition name="fade" mode="out-in">
        <table v-if="hasLoadedTexts" class="table is-fullwidth">
          <tbody>
            <tr>
              <td>
                {{ $t('Components.Reservation.ModalSendMail.Label_From') }}
              </td>
              <td>
                <div>
                    <ui-base-input-field
                      ref="mailaddressInput"
                      fieldType="text"
                      :showLabel="false"
                      v-model="mail.FromEmail"
                      :error="fromEmailErrors"
                      placeholder="Add mailaddress"
                      @input="$v.mail.FromEmail.$touch()"
                      @blur="$v.mail.FromEmail.$touch()"
                    />
                </div>
              </td>
            </tr>
            <tr>
              <td>{{ $t('Components.Reservation.ModalSendMail.Label_To') }}</td>
              <td>
                <MailInput
                  :mailaddresses="mailAddresses"
                  @mailinputChecked="setMailInputEmpty"
                  @mailaddressAdded="addMailAddress"
                  @mailaddressDeleted="deleteMailAddress"
                  @emailErrorPresent="hasToAddressWarning"
                />
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('Components.Reservation.ModalSendMail.Label_BCC') }}
              </td>
              <td>
                <MailInput
                  :mailaddresses="bccAddresses"
                  @mailinputChecked="setMailInputEmpty"
                  @mailaddressAdded="addBCCAddress"
                  @mailaddressDeleted="deleteBCCAddress"
                  @emailErrorPresent="hasToAddressWarning"
                />
              </td>
            </tr>
            <tr>
              <td width="100">
                {{ $t('Components.Reservation.ModalSendMail.Label_MailType') }}
              </td>
              <td>
                <div class="select">
                  <select v-model="selectedMailText">
                    <option
                      v-for="(mailText, index) in mailTexts"
                      :key="index"
                      :value="mailText"
                      :selected="selectedMailText.Id === mailText.Id"
                      >{{ mailText.MailType + ' ' + mailText.Name }}</option
                    >
                  </select>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('Components.Reservation.ModalSendMail.Label_Subject') }}
              </td>
              <td>
                <div class="control">
                  <ui-base-input-field
                    :showLabel="false"
                    fieldType="text"
                    placeholder="Subject"
                    :error="subjectErrors"
                    v-model="selectedMailText.Subject"
                    @input="$v.selectedMailText.Subject.$touch()"
                    @blur="$v.selectedMailText.Subject.$touch()"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('Components.Reservation.ModalSendMail.Label_Text') }}
              </td>
              <td>
                <div class="control">
                  <ui-text-editor
                    :key="'editor' + selectedMailText.Id"
                    v-model="selectedMailText.Body"
                    @input="$v.selectedMailText.Body.$touch()"
                    @blur="$v.selectedMailText.Body.$touch()"
                    :class="{ 'cke_chrome': hasBodyWarning }"
                  ></ui-text-editor>
                </div>
                 <div v-if="bodyErrors.length" class="has-text-danger"><small>{{bodyErrors}}</small></div>
              </td>
            </tr>
          </tbody>
        </table>
      </transition>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import mailProvider from '@/providers/mail'

const MailInput = () => import('@/components/UI/Components/MailInput')

import { required, email, minLength } from 'vuelidate/lib/validators'
const touchMap = new WeakMap()

export default {
  components: {
    MailInput,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      fromEmail: '',
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      bccAddresses: [],
      hasLoadedTexts: false,
      mail: {
        FromEmail: '',
        // ToEmail: '',
        // CC: '',
        // Subject: '',
        // Body: '',
      },
      mailAddresses: [],
      mailTexts: [],
      selectedMailText: null,
      addressWarning: false
    }
  },

  /**
   * Form validation rules
   */
  validations() {
    let output = {
      FromEmail: {
        required,
        email
      }
    }
    let output2 = {
      Subject: {
        required,
        minLength: minLength(3)
      },
      Body: {
        required,
        minLength: minLength(10)
      }

    }
    return {
      mail: output,
      selectedMailText: output2,
    }
  },

  computed: {
    ...mapGetters({ profile: 'profileStore/getProfile' }),
    ...mapState('reservationStore', ['reservation']),

    fromEmailErrors() {
      const errors = []
      if (!this.$v.mail.FromEmail.$dirty) return errors
      if (!this.$v.mail.FromEmail.required) {
        errors.push(this.$t('Form.InputErrors.Required'))
      } else if (!this.$v.mail.FromEmail.email) {
        errors.push(this.$t('Form.InputErrors.InvalidEmail'))
      } 
      return errors
    },

    subjectErrors() {
      const errors = []
      if (!this.$v.selectedMailText.Subject.$dirty) return errors
      !this.$v.selectedMailText.Subject.required &&
        errors.push(this.$t('Form.InputErrors.Required'))
      !this.$v.selectedMailText.Subject.minLength &&
        errors.push(this.$t('Form.InputErrors.minLength', { minLength: 3 }))
      return errors
    },

     bodyErrors() {
      let errors = ""
      if (!this.$v.selectedMailText.Body.$dirty) return errors
      if (!this.$v.selectedMailText.Body.required) {
        errors = this.$t('Form.InputErrors.Required')
      }
      if (!this.$v.selectedMailText.Body.minLength) {
       errors = this.$t('Form.InputErrors.minLength', { minLength: 3 })
      }
      return errors
    },

    validEmailSettings() {
      let result = true

      if (
        this.hasFromAddressWarning ||
        this.addressWarning ||
        this.hasSubjectWarning ||
        this.hasBodyWarning
      ) {
        result = false
      }
      return result
    },

    hasFromAddressWarning() {
      let result = false

      if (this.fromEmailErrors.length > 0) {
        result = true
      }

      return result
    },

    hasSubjectWarning() {
      let result = false

     if (this.subjectErrors.length > 0 || !this.selectedMailText.Subject) {
       this.$v.$touch()
       result = true
      }

      return result
    },

    hasBodyWarning() {
      let result = false

      if (this.bodyErrors.length > 0 || !this.selectedMailText.Body) {
       this.$v.$touch()
       result = true
      }
      return result
    },
  },

  created() {
    this.mail.FromEmail = this.profile.Email
    this.selectedMailText = { Id: 0 }

    this.mailAddresses = this.reservation.Contacts.filter(
      (c) => c.ReceiveEmail
    ).map((c) => c.Email)

    this.getLocationReservationMailTexts()
  },

  methods: {
    getLocationReservationMailTexts() {
      let self = this
      self.hasLoadedTexts = false

      mailProvider.methods
        .getReservationMailTextsByReservationId(self.reservation.Id)
        .then((response) => {
          if (response.status === 200) {
            self.mailTexts = response.data

            self.selectedMailText = JSON.parse(
              JSON.stringify(self.mailTexts[0])
            )
          }
        })
        .finally(() => {
          self.hasLoadedTexts = true
        })
    },

    hasToAddressWarning(emailError) {
      if (emailError.length > 1) {
        this.addressWarning = true
      } else {
        this.addressWarning = false
      }
    },

    addMailAddress(address) {
      this.mailAddresses.unshift(address)
    },

    deleteMailAddress(address) {
      let addressIndex = this.mailAddresses.findIndex((t) => t === address)
      if (addressIndex > -1) {
        this.mailAddresses.splice(addressIndex, 1)
      }
    },

    addBCCAddress(address) {
      this.bccAddresses.unshift(address)
    },

    deleteBCCAddress(address) {
      let addressIndex = this.bccAddresses.findIndex((t) => t === address)
      if (addressIndex > -1) {
        this.bccAddresses.splice(addressIndex, 1)
      }
    },

    setMailInputEmpty(value) {
      if (value) {
        this.addressWarning = false
      }
    },

    sendMail() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true
        this.$v.$touch()
          if (this.$v.$invalid) {
          // 'ERROR'
          self.isSaving = false
        } else {
        let mail = {
          FromEmail: this.mail.FromEmail,
          ToEmail: self.mailAddresses.join(','),
          CC: self.bccAddresses.join(','),
          Subject: self.selectedMailText.Subject,
          Body: self.selectedMailText.Body,
        }

        mailProvider.methods
          .sendReservationMail(self.reservation.Id, mail)
          .then((response) => {
            self.isSavingSuccess = true

            self.$emit('reservationMailSent')

            setTimeout(() => {
              self.onClickCancel()
            }, 1000)
          })
          .catch((e) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/template.scss';

.row-whyTags {
  display: flex;
  align-items: baseline;
  .message {
    margin-bottom: 0;
    padding: 2px 5px;
  }
}

.cke_chrome{
    border-radius: 4px;
    border: 1px solid red;
    border-width: thin;        
}

.is-borderless,
.is-borderless:hover {
  border-color: transparent;
}

.field.is-grouped.is-grouped-multiline {
  align-items: baseline;
  background-color: $white;
  min-height: 80px;
  height: auto;

  &:last-child {
    margin-bottom: $gap;
  }

  .mailaddresses {
    .mailaddress {
      font-size: $size-6;
    }
    a.is-delete {
      color: $text;
    }
  }

  .wrapper-input {
    align-items: center;
    display: inline-flex;
    white-space: nowrap;
    .input-mailaddress {
      min-width: 200px;
      width: 200px;
      margin-right: 5px;
      border-left: transparent;
      border-top: transparent;
      border-right: transparent;
    }
  }
}
</style>
